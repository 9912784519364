import React from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchOpenInterestForToken} from "@/api/perpsDataFetcher";
import {ErrorPanel} from "@/components/ErrorPanel";
import {Tooltip as ReactTooltip} from "react-tooltip";
import useId from "@/hooks/useId";
import {SplitLabelValue} from "@/components/SplitLabelValue";
import {OpenInterest, PerpToken} from "@/components/Perps/types";
import {EthValue} from "@/components/Perps/EthValue";
import {parseEther} from "viem";
import {isUsdToken} from "@/util/chainConstants";

export type Props = {
    token: PerpToken;
    openInterest?: OpenInterest[] | undefined;
    displayTotalPrincipal?: boolean;
};

const calculatePercentages = (data: OpenInterest[] | undefined) => {
    if (!data || data.length === 0) {
        return { longPercentage: 50, shortPercentage: 50, longOpenInterest: 0, shortOpenInterest: 0 };
    }

    const longPosition = data.find(entry => entry.side === "LONG");
    const shortPosition = data.find(entry => entry.side === "SHORT");

    const totalPositions = data.reduce((total, entry) => total + entry.amount, 0);

    let longPercentage = longPosition ? (longPosition.amount / totalPositions) * 100 || 0 : 0;
    let shortPercentage = shortPosition ? (shortPosition.amount / totalPositions) * 100 || 0 : 0;

    if (longPosition && !shortPosition) {
        longPercentage = 100;
    } else if (shortPosition && !longPosition) {
        shortPercentage = 100;
    }

    const isUSDB = isUsdToken(data[0].tokenAddress);
    let longPrincipal = longPosition?.principal || 0;
    let shortPrincipal = shortPosition?.principal || 0;
    let longOpenInterest = longPosition?.amount || 0;
    let shortOpenInterest = shortPosition?.amount || 0;

    if (isUSDB) {
        const tmpLongPrincipal = longPrincipal;
        const tmpLongPercentage = longPercentage;
        const tmpLongOpenInterest = longOpenInterest;
        longPrincipal = shortPrincipal;
        longPercentage = shortPercentage;
        longOpenInterest = shortOpenInterest;
        shortPrincipal = tmpLongPrincipal;
        shortPercentage = tmpLongPercentage;
        shortOpenInterest = tmpLongOpenInterest;
    }

    return {
        longPercentage,
        shortPercentage,
        longOpenInterest,
        shortOpenInterest,
        longPrincipal,
        shortPrincipal,
        isUSDB,
    };
};

const renderSkeletonLoading = () => (
    <div className="rounded-md w-full max-w-[400px] h-[6px] bg-slate-600 animate-pulse"/>
);

export const OpenInterestView = ({token, openInterest, displayTotalPrincipal}: Props) => {
    const id = useId();

    const openInterestStatsQuery = useQuery({
        queryKey: ["open_interest", token.address],
        queryFn: async () =>  openInterest || await fetchOpenInterestForToken(token.address),
        gcTime: 20 * 1000,
        staleTime: 20 * 1000,
        refetchInterval: 20 * 1000,
      }
    );

    if (openInterestStatsQuery.isLoading) {
        return renderSkeletonLoading();
    }

    if (openInterestStatsQuery.isError) {
        return <ErrorPanel message="Error loading open interest stats"/>;
    }

    const {longPercentage, shortPercentage, longOpenInterest, shortOpenInterest, longPrincipal, shortPrincipal, isUSDB} = calculatePercentages(
        openInterestStatsQuery.data
    );

    const maxOpenInterest = isUSDB ? token.maxShortPrincipal : token.maxOpenInterest;
    const maxShortPrincipal = isUSDB ? token.maxOpenInterest : token.maxShortPrincipal;

    return (
        <div className="flex items-center space-x-1 cursor-help" id={`oiv_${id}`}>
            <div
                className="h-[6px] bg-call rounded-l-md hover:bg-call-50"
                style={{width: `${longPercentage}%`}}
                data-tip={`Long: ${longPercentage.toFixed(2)}%`}
            />
            <div
                className="h-[6px] bg-put rounded-r-md hover:bg-call-50"
                style={{width: `${shortPercentage}%`}}
                data-tip={`Short: ${shortPercentage.toFixed(2)}%`}
            />
            <ReactTooltip
                anchorSelect={`#oiv_${id}`}
                id={`tooltip_oiv_${id}`}
                place="top"
                className="z-50"
                style={{backgroundColor: "#3b485f", color: "#98a2b3"}}
            >
                <div className="standard-stack p-2">
                    <SplitLabelValue label="Long Open Interest: " className="text-white">
                        <div className="flex flex-col items-end">
                            <div className="text-white">
                                <EthValue value={parseEther(`${longOpenInterest}`)} className="text-white"/>
                            </div>
                            <div className="text-white">
                              ({longPercentage.toFixed(2)}%)
                            </div>
                        </div>
                    </SplitLabelValue>
                    {
                        displayTotalPrincipal &&
                        <>
                            <SplitLabelValue label="Long Principal: " className="text-white">
                                <div className="flex flex-col items-end">
                                    <div className="text-white">
                                        {
                                            isUSDB
                                              ? `$${longPrincipal.toLocaleString([], {maximumFractionDigits: 0})}`
                                              : <EthValue value={parseEther(`${longPrincipal}`)} className="text-white"/>
                                        }
                                    </div>
                                </div>
                            </SplitLabelValue>
                            {
                                maxOpenInterest &&
                                <SplitLabelValue label="Long Principal Limit: " className="text-white">
                                  <div className="flex flex-col items-end">
                                      <div className="text-white">
                                          {
                                              isUSDB
                                                ? `$${maxOpenInterest.toLocaleString([], {maximumFractionDigits: 0})}`
                                                : <EthValue value={parseEther(`${maxOpenInterest}`)} className="text-white"/>
                                          }
                                      </div>
                                  </div>
                                </SplitLabelValue>
                            }
                        </>
                    }
                    <SplitLabelValue label="Short Open Interest: " className="text-white">
                        <div className="flex flex-col items-end">
                            <div className="text-white">
                                <EthValue value={parseEther(`${shortOpenInterest}`)} className="text-white"/>
                            </div>
                            <div className="text-white">
                                ({shortPercentage.toFixed(2)}%)
                            </div>
                        </div>
                    </SplitLabelValue>
                    {
                      displayTotalPrincipal &&
                      <>
                          <SplitLabelValue label="Short Principal: " className="text-white">
                              <div className="flex flex-col items-end">
                                  <div className="text-white">
                                      {
                                          isUSDB
                                            ? <EthValue value={parseEther(`${shortPrincipal}`)} className="text-white"/>
                                            : `${(shortPrincipal || 0).toLocaleString([], {maximumFractionDigits: 0})} ${token.symbol}`
                                      }
                                  </div>
                              </div>
                          </SplitLabelValue>
                          {
                              maxShortPrincipal &&
                                <SplitLabelValue label="Short Principal: " className="text-white">
                                    <div className="flex flex-col items-end">
                                        <div className="text-white">
                                            {
                                                isUSDB
                                                  ? <EthValue value={parseEther(`${maxShortPrincipal}`)} className="text-white"/>
                                                  : `${(maxShortPrincipal || 0).toLocaleString([], {maximumFractionDigits: 0})} ${token.symbol}`
                                            }
                                        </div>
                                    </div>
                                </SplitLabelValue>
                          }
                      </>
                    }
                </div>
            </ReactTooltip>
        </div>
    );
};
